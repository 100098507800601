import React from 'react'
import cx from 'classnames'
import { useStores } from 'config/store'
import { observer } from 'mobx-react'

import { useStyles } from './MailingDetails.styles'
import { Paper, Theme, useMediaQuery } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Close as CloseIcon } from '@material-ui/icons'
import moment from 'moment'
import DeliveredMailingCardInfo from 'components/Mailings/DeliveredMailingCardInfo'
import { MailingStatusSteps } from '../MailingStatusSteps'
import { MailingDetailsTab } from '../MailingDetailsTab'
import classNames from 'classnames'

interface IMailingDetails {
  onClose: () => void
}

export const MailingDetails = (props: IMailingDetails) => {
  const { onClose } = props
  const classes = useStyles()
  const { mailingsStore } = useStores()
  const mailing = mailingsStore.drawer
  const mobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  if (!mailing) {
    return <h1>Loading</h1>
  }

  const mailingDate =
    mailing.deliveryWeek !== null
      ? moment(mailing.deliveryWeek).format('MM/DD/YY')
      : ''

  return (
    <div className={classes.container}>
      <Paper
        elevation={16}
        className={classNames(
          classes.contentWrapper,
          mobileDevice ? classes.mobileContainer : null
        )}
      >
        <div
          className={cx(
            classes.header,
            mailing.isLoading ? classes.loading : null
          )}
        >
          <h1>Campaign</h1>
          <span>
            {mailingDate} - {mailing.campaignType}
          </span>

          <div className={classes.closeWrapper} onClick={() => onClose()}>
            <IconButton className={classes.close}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
        </div>

        <DeliveredMailingCardInfo size="large" mailing={mailing} />
        <div className={classes.title}>Mailing Status</div>
        <MailingStatusSteps active={mailing.status} />
        <MailingDetailsTab mailing={mailing} />
      </Paper>
    </div>
  )
}

export default observer(MailingDetails)
